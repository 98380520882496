import React from 'react';
import { Link } from 'react-router-dom';
import useViewport from 'hooks/useViewport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedinIn,
  faPinterestP
} from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const { width } = useViewport();
  if (width <= 640) return null;

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-items">
          <ul className="learn-more">
            <li>
              <p>Learn</p>
            </li>
            <li>
              <Link to="/safety">Safety & Trust</Link>
            </li>
            <li>
              <Link to="/covid">Covid</Link>
            </li>
            <li>
              <Link to="/call-emmy-promise">Our Promise</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/referral-program">
                Invite a Fairy & Sparkle Program
              </Link>
            </li>
          </ul>
          <ul className="help-and-support">
            <li>
              <p>Support</p>
            </li>
            <li>
              <Link to="/faq">FAQs (Parents)</Link>
            </li>
            <li>
              <Link to="/faq-partners">FAQs (Partners)</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li className="link">
              <Link to="/apply" className="link-button">
                Become a Fairy
              </Link>
            </li>
          </ul>
          <ul className="about">
            <li>
              <p>About</p>
            </li>
            <li>
              <Link to="/our-story">Our story</Link>
            </li>
            <li>
              <Link to="/team">Meet the Team</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-social-networks">
        <span className="title">Stay Connected</span>
        <div className="icons">
          <Link to="/facebook" target="_blank">
            <FontAwesomeIcon icon={faFacebookF} size="2x" />
          </Link>
          <Link to="/instagram" target="_blank">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </Link>
          <Link to="/twitter" target="_blank">
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </Link>
          <Link to="/linkedin" target="_blank">
            <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
          </Link>
          <Link to="/pinterest" target="_blank">
            <FontAwesomeIcon icon={faPinterestP} size="2x" />
          </Link>
        </div>
      </div>
      <p className="footer-rights">
        © 2022 Call Emmy, Inc. ·{' '}
        <Link to="/privacy-policy">Privacy Policy</Link> ·{' '}
        <Link to="/terms-of-service">Terms of Use</Link>
      </p>
    </footer>
  );
};

export default Footer;
